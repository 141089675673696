import React from "react"
import SEO from "../components/seo"
import { FaEnvelope } from "react-icons/fa"

import "../scss/youcloud.scss"

import CloudLogo from "../assets/img/youCloud/cloud.png"
import Chat from "../assets/img/youCloud/chat.svg"
import Meeting from "../assets/img/youCloud/meet.svg"
import Web from "../assets/img/youCloud/web.svg"
import Computer from "../assets/img/youCloud/computer.svg"
import RocketLogo from "../assets/img/youCloud/Rocket.svg"
import FinanceeLogo from "../assets/img/youCloud/financee.svg"
import SupportLogo from "../assets/img/youCloud/support.svg"

const IndexPage = () => {
  return (
    <div>
      <SEO title="Youcloud" keywords={[`youpal`, `youpal group`, `youCloud`]} />
      <div className="youCloudMain">
        <div className="pl-10-percent mb-5 internalSection">
          <div className="row  mainBackgroundImage">
            <div className="col col-sm-6">
              <h2 className="text-left mainTitle fsm-28 gradiantHeader">
                Behind Youcloud is another cloud
              </h2>
              <div className="sideContent">
                <p className="fsm-10">
                  Data is the most valuable asset for everyone. Data is the
                  beginning of the story, the progress of the story as well as
                  the end. As your activity and business become more complex and
                  evolves, Youcloud grows and develops with you. Youcloud is
                  where you can personalize and manage your own cloud.
                </p>
              </div>
            </div>
            <div className="col col-sm-6 ">
              <div className="cloud-img-mb"></div>
            </div>
          </div>
        </div>
        <div className="mainBody">
          <section className="container">
            <div className="col">
              <h3 className="internalNarrowCol gradiantHeader">
                Youcloud Experience
              </h3>
              <p className="text-left internalNarrowCol">
                The experience of data analyses and security is one of the most
                important in developing a healthy informational environment. We
                empower our customers with the best practice and experience of
                Cloud networking. Our solutions offer the best technology
                options, enabling the full usage of the cloud’s operational
                functionality.
              </p>
            </div>
          </section>

          <section className="container contactsSection">
            <div className="row">
              <div className="p-4 mb-5 col-lg-4 offset-lg-1 col card">
                <div className="d-flex justify-content-end">
                  <img src={Chat} />
                </div>
                <h3>Chat</h3>
                <p>
                  This should handle about asynchronous communication between
                  teams so they can work together and not be slacking at work.
                  For the business the data stays in own domain.
                </p>
              </div>
              <div className="col-lg-1"></div>
              <div className="p-4 mb-5 col-lg-4 col card">
                <div className="d-flex justify-content-end">
                  <img src={Meeting} />
                </div>
                <h3>Meet</h3>
                <p>
                  Meet is our teleconferencing solution it has end to end
                  encryption and can be used directly in chat and many other
                  solutions. Virtual meetings are now productive, fun and easy
                  to access.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="p-4 mb-5 col-lg-4 offset-lg-1 col card">
                <div className="d-flex justify-content-end">
                  <img src={Computer} />
                </div>
                <h3>Suite</h3>
                <p>
                  Having the right office workflow makes your team more
                  productive and collaborate better. This in your own private
                  office suite so competitors including Google won't feed off
                  your sensitive data
                </p>
              </div>
              <div className="col-lg-1"></div>
              <div className="p-4 mb-5 col-lg-4 col card card-web">
                <div className="d-flex justify-content-end">
                  <img src={Web} />
                </div>
                <div className="d-flex flex-column">
                  <h3 className="text-left cardTitle">Web</h3>
                  <p className="text-left">
                    From our customers frustration we build YouPage from
                    scratch, it is blazing fast and can host your content
                    management system, progressive web app or API backend in a
                    secure and safe way.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="container">
            <h3 className="internalNarrowCol gradiantHeader text-center midTitle">Key Features</h3>
          </section>

          <section className="container my-5 text-left images">
            <div className="p-3 my-5 row">
              <div className="col-lg-6 col leftSide">
                <div>
                  <h2 style={{ color: "#529BFF" }}>Security and Integration</h2>
                  <p className="pr-5 mb-5 w-75">
                    The process of integration can be tedious and holding
                    certain risks. Besides, changes and updates made through a
                    cloud supplier's web support are difficult to validate, hard
                    to move back, and difficult to review. The challenge the
                    Youcloud has faced at this stage is figuring out how to
                    conjure another tool for a particular host to fix its
                    configurations and features.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col rightSide">
                <img src={RocketLogo} alt="RocketLogo" />
              </div>
            </div>

            <div className="p-3 my-5 row">
              <div className="col-lg-6 col imgSm">
                <img src={FinanceeLogo} alt="financeeLogo" />
              </div>
              <div className="col-lg-6 col">
                <div>
                  <h2 style={{ color: "#FFA71F" }}>Consultancy</h2>
                  <p className="pr-5 w-75">
                    Having a creative and productive team always determines the
                    success of the business. Yet giving the right roadmap for
                    the solutions is what makes success and progress even
                    faster. Traditional approaches have forced us to create new
                    resources and reroute old ways. With Youcloud we can lead
                    you to new solutions and introduce to new tools.
                  </p>
                </div>
              </div>
            </div>

            <div className="p-3 my-5 row">
              <div className="col-lg-6 col leftSide">
                <div>
                  <h2 style={{ color: "#00CEDF" }}>DevOps Management</h2>
                  <p className="pr-5 w-75">
                    The main goal of the Youcloud is to build a single place for
                    the center of the DevOps tool. To enable the goal, Youcloud
                    was initially created with pliability in mind. Not only we
                    offer centralized tools, but also your business and activity
                    acceleration. We provide DevOps management and culture
                    customization for your team.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 rightSide">
                <img src={SupportLogo} alt="SupportLogo" />
              </div>
            </div>
          </section>

          <section className="container contactBackground">
            <div className="row contactBox">
              <div className="col">
                <h1 className="gradiantHeader">
                  The sky’s the limit, but so is the cloud
                </h1>
                <div className="sendLinkContainer">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="your@email.com"
                      aria-label="Your Email"
                      aria-describedby="button-addon2"
                    />
                    <div className="input-group-append">
                      <button
                        className="px-4 py-2 btn btn-outline-secondary"
                        type="button"
                        id="button-addon2"
                      >
                        Join Us
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default IndexPage
